import moment from "moment"
import momentDurationFormatSetup from "moment-duration-format"

momentDurationFormatSetup(moment)


const helpers = {

  /**
   * Dorian - 2024-05-13
   * Use useDocumentVisibility (vueUse) instead
   */
  onVisibilitySwitch(onVisible = null, onHidden = null, disable = false) {
    // Set the name of the hidden property and the change event for visibility
    let hidden, visibilityChange
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden"
      visibilityChange = "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden"
      visibilityChange = "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden"
      visibilityChange = "webkitvisibilitychange"
    }

    function handleVisibilityChange() {
      if (document[hidden]) {
        if(onHidden) onHidden()
      } else {
        if(onVisible) onVisible()
      }
    }

    disable ?
      document.removeEventListener(visibilityChange, handleVisibilityChange, false) :
      document.addEventListener(   visibilityChange, handleVisibilityChange, false)
  },


  nameForScan(scan) {
    return scan?.user_name || "Étudiant non trouvé"
  },

  goToQuizEditor(quiz, router) {
    router.push({
      name: "quizEditor",
      params: { quizId: quiz.id },
    })
  },

  quizDuration(quiz) {
    return moment
      .duration(quiz.time_limit, "seconds")
      .format("H[h] m[min]", { trunc: true })
  },

  /**
   * @params {Number} duration
   * @return {String}
   */
  scoreDuration(duration) {
    if(!duration) return "-"

    return moment
      .duration(new Date(duration * 1000))
      .format("H[h] m[min] s[s]", { trunc: true })
  },

  quizDurationPerQuestion(quiz) {
    if(quiz.questions_count === 0) return "0s 😇"

    return moment
      .duration(quiz.time_limit / quiz.questions_count, "seconds")
      .format("H[h] m[min] ss[s]", { trunc: true })
  },

  removeHTML(string) {
    if (!string) return ""

    const regex = /(<([^>]+)>)/ig
    return string.replace(regex, "")
  },

  emailIsValid (email) {
    email = email.trim()
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  },

  isSameDay(date1, date2) {
    const d1 = new Date(date1)
    const d2 = new Date(date2)
    return d1.getFullYear() === d2.getFullYear() &&
         d1.getMonth() === d2.getMonth() &&
         d1.getDate() === d2.getDate()
  },

  removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  },

  formatForSearch(str) {
    return this.removeAccents(str.toLocaleLowerCase())
  },

  isInIframe() {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  },

}

export default helpers
